<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pb-12 max-w-3xl">
      <div
        v-if="expressEnabled"
        class="mt-8 max-w-md mx-auto">
        <div class="flex justify-center">
          <div
            v-if="!addons"
            class="loader" />
          <checkbox-list
            v-else
            :options="addons"
            v-model="selectedAddons"
            class="mb-4"
            label-class="font-bold"
            alignment="top">
            <template slot-scope="{ item }">
              <div>
                <div class="mb-1">
                  <span class="font-bold">{{ item.title }}</span> <span class="font-bold">+{{ item.price }} kr</span>
                </div>
                <span class="block text-sm text-muted">
                  {{ item.description }}
                </span>
              </div>
            </template>
          </checkbox-list>
        </div>

        <div class="text-center text-md mt-8 font-bold">
          Totalt å betale {{ totalAmount }} kr
        </div>
      </div>

      <div class="text-center mt-12 mb-4">
        <default-button
          primary
          large
          :loading="submitting"
          @click.prevent.native="submit">
          {{ buttonText }}
        </default-button>
      </div>
      <div class="text-center">
        <default-button
          @click.prevent.native="prev">
          Gå tilbake
        </default-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },

    formValue: {
      type: Number,
      default: 0
    },

    setFormData: {
      type: Function,
      default () {
        return () => {}
      }
    },

    prev: {
      type: Function,
      default () {
        return () => {}
      }
    },

    submitting: {
      type: Boolean,
      default: false
    },

    submit: {
      type: Function,
      default () {
        return () => {}
      }
    },

    expressEnabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      addons: null,
      loadingAddons: false
    }
  },

  mounted () {
    this.loadingAddons = true
    axios.get("/api/addons")
      .then(({ data }) => {
        this.loadingAddons = false
        this.addons = data
      })
  },

  computed: {
    selectedAddons: {
      get () {
        return this.formData.addons
      },

      set (value) {
        this.setFormData("addons", value)
      }
    },

    buttonText () {
      return this.data.buttonText || "Gå til betaling"
    },

    totalAmount () {
      return this.formData.addons.reduce((total, item) => {
        let addon = this.addons.find(addon => addon.id == item)
        return total + addon.price
      }, this.formValue)
    }
  }
}
</script>
